<template>
  <div class="create-release">
    <custom-modal
        :opened="showModal"
        :max-width="650"
        class-name="add-localization-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper">
        <div class="title">Select localizations you want to add</div>
        <div v-if="showChangedLocales" class="changed-locales-container">
          Show changed locales only
          <switch-toggle @toggle-changed="handleToggleChange"></switch-toggle>
        </div>
        <basic-search-input
            placeholder="Search localization"
            :clear-input="searchInput"
            @search-input-changed="searchInputChanged"
            :hide-results-block="true">
          <template v-slot:search-icon>
            <svg-icon class="search-icon" icon="search-solid"/>
          </template>
        </basic-search-input>
        <div class="locales">
          <div class="locale" v-for="locale in locales" :key="locale.code">
            <input type="checkbox"
                   :id="locale.code"
                   :name="locale.code"
                   class="common-checkbox"
                   :disabled="alreadySelectedLocales.includes(locale.code)"
                   :checked="alreadySelectedLocales.includes(locale.code)"
                   @change="onLocalClicked"
            />
            <label :for="locale.code">
              <dynamic-image classes="country-flag"
                             :width="18"
                             :height="14"
                             :size="32"
                             :country-code="getLocaleCountryCode(locale.code)?.countryCode"/>
              {{ getLocaleCountryCode(locale.code)?.localeName  + " (" + locale.code + ")" }}
            </label>
          </div>

        </div>
        <div class="button-container">
          <template v-if="showPreloader">
            <preloader-table-small :loader-size="'40px'"></preloader-table-small>
          </template>
          <template v-else>
            <base-button height="40px"
                         width="180px"
                         class="btn-standard save-edit-btn"
                         border-radius="4px"
                         font-size="16px"
                         :disabled="selectedLocales.length === 0"
                         @click="submit">
              Add
              <template v-if="selectedLocales.length > 0">
                ({{ selectedLocales.length }})
              </template>
            </base-button>
          </template>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import {httpRequest} from "@/api";
import {apiUrls} from "@/api/api";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import RadioButton from "@/components/UI/RadioButton/index.vue";
import SwitchToggle from "@/components/UI/SwitchToggle/index.vue";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";


export default {
  name: "AddLocalizationModal",
  components: {PreloaderTableSmall, SwitchToggle, RadioButton, SvgIcon, BasicSearchInput},
  emits: ['modal-closed', 'modal-submit'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentApp: {
      type: Object,
      required: true,
    },
    showChangedLocales: {
      type: Boolean,
      required: true,
    },
    changedLocales: {
      type: Object,
    },
    alreadySelectedLocales: {
      type: Array,
    },
    releaseDate: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      showPreloader: false,
      toggleChecked: false,
      initialLocales: [],
      locales: [],
      selectedLocales: [],
      searchInput: '',
    }
  },
  async mounted() {
    await this.fetchLocales();
  },
  watch: {
    currentApp(newApp, oldApp) {
      if (newApp.id !== oldApp.id) {
        this.fetchLocales();
      }
    },
    showModal() {
      this.selectedLocales = [];
    }
  },
  methods: {
    getLocaleCountryCode,
    close() {
      this.toggleChecked = false;
      this.$emit('modal-closed');
    },
    async submit() {
      this.showPreloader = true;
      const localeCodes = [this.selectedLocales.map(item => item.code), ...this.alreadySelectedLocales];
      const urlParameters = `?app_id=${this.currentApp.id}&date=${this.formatReleaseDate()}&locale_codes=${encodeURIComponent(localeCodes.join(','))}`;
      const metaData = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + apiUrls.metadataJournal.GET_METADATA_BY_LOCALES + urlParameters,
      );
      this.toggleChecked = false;
      this.showPreloader = false;
      this.$emit('modal-closed', metaData);
    },
    searchInputChanged(search) {
      this.searchInput = search;
      this.setFilteredLocales();
      this.locales = this.locales.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
    },
    async fetchLocales() {
      const param = '?store_key=' + this.currentApp.store.key;
      this.initialLocales = await httpRequest(
          'GET', process.env.VUE_APP_API_URL + apiUrls.metadataJournal.GET_LOCALES + param
      );
      this.locales = this.initialLocales;
    },
    onLocalClicked(event) {
      const clickedLocale = this.locales.find(locale => locale.code === event.target.id);

      if (clickedLocale) {
        const isChecked = event.target.checked;

        if (isChecked) {
          this.selectedLocales.push(clickedLocale);
        } else {
          this.selectedLocales = this.selectedLocales.filter(locale => locale.code !== clickedLocale.code);
        }
      }
    },
    handleToggleChange(value) {
      this.toggleChecked = value;
      this.setFilteredLocales();
      this.searchInputChanged(this.searchInput)
    },
    setFilteredLocales() {
      this.locales = this.toggleChecked
          ? this.initialLocales.filter(item => this.changedLocales.includes(item.code))
          : this.initialLocales;
    },
    formatReleaseDate() {
      const date = new Date(this.releaseDate);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return`${year}-${month}-${day}`;
    },
  },
}
</script>


<style lang="scss" src="./styles.scss"></style>