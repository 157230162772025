<template>
  <div class="create-release">
    <custom-modal
        :opened="showModal"
        :max-width="650"
        class-name="load-from-meta-editor-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper">
        <div class="title">Upload from Meta Editor</div>
        <div class="metadata" @mouseleave="showLocalesFullList = false">
          <table>
            <tr>
              <th>Metadata Version Name</th>
              <th>Date</th>
              <th>Changed Locales</th>
            </tr>
            <tr v-for="release in releases" @click="selectRelease(release)" @mouseenter="showLocalesFullList = false">
              <td>
                <div class="td-content">
                    <radio-button @click="selectRelease(release)"
                                  :active="selectedRelease === release">
                    </radio-button>
                    {{ getReleaseName(release) }}
                </div>
              </td>
              <td>{{ formatDate(release.updated_at) }}</td>
              <td>
                <div class="locales-list-container">
                  <div class="locales">
                    Locales ({{ Object.keys(release.locales).length }})
                  </div>
                  <div class="locales-list">
                    <dynamic-image v-for="(data, locale) in release.locales"
                                   classes="country-flag"
                                   :width="18"
                                   :height="14"
                                   :size="32"
                                   :country-code="getLocaleCountryCode(locale)?.countryCode"/>

                  </div>
                  <div
                      :id="`locales-${release.id}`"
                      class="three-dots"
                      :class="{ show : Object.keys(release.locales).length > visibleLocalesCount}"
                      @mouseenter="onListLocalesHover(release)"
                  >
                    ...
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="show-more-locales-container common-scrollbar"
             :class="{active : showLocalesFullList }"
             @mouseleave="showLocalesFullList = false"
             @mouseenter="showLocalesFullList = true"
        >
          <ul class="locales-list-hover">
            <li v-for="(data, locale) in hoverRelease.locales">
              <dynamic-image classes="country-flag"
                             :width="18"
                             :height="14"
                             :size="32"
                             :country-code="getLocaleCountryCode(locale)?.countryCode"/>
              {{ getLocaleCountryCode(locale)?.localeName + " (" + locale + ")"}}
            </li>
          </ul>
        </div>
        <div class="next-button">
          <base-button height="36px"
                       width="171px"
                       class="btn-standard save-edit-btn"
                       border-radius="4px"
                       font-size="16px"
                       :disabled="JSON.stringify(selectedRelease) === '{}'"
                       @click="submit()"
          >
          Add
          </base-button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {httpRequest} from "@/api";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import {formatDate} from "@/utils/utils";
import RadioButton from "@/components/UI/RadioButton/index.vue";

export default {
  name: 'LoadReleaseFromMetaEditorModal',
  components: {RadioButton, SvgIcon},
  emits: ['modal-closed', 'modal-submit'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentApp: {
      type: Object,
    },
  },
  data () {
    return {
      releases: {},
      selectedRelease: {},
      hoverRelease: {},
      visibleLocalesCount: 5,
      showLocalesFullList: false,
    }
  },
  async mounted() {
    await this.fetchAppSavedMetas();
  },
  watch: {
    async currentApp(newApp, oldApp) {
      if (newApp.id !== oldApp.id) {
        await this.fetchAppSavedMetas();
      }
    }
  },
  methods: {
    formatDate,
    getLocaleCountryCode,
    close() {
      this.$emit('modal-closed');
    },
    submit() {
      this.$emit('modal-submit', this.selectedRelease.locales);
    },
    async fetchAppSavedMetas() {
      this.releases = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.GET_SAVED_METAS + `?app_id=${this.currentApp.id}`
      );
    },
    getReleaseName(release) {
      return release.name + ' from ' +  formatDate(release.updated_at);
    },
    selectRelease(value) {
      this.selectedRelease = value;
    },
    onListLocalesHover(release) {
      if (!this.showLocalesFullList) {
        const element =  document.querySelector(`#locales-${release.id}`);
        const listLocales = document.querySelector(`.show-more-locales-container`);
        const rect = element.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        let modalLeft = centerX - (window.innerWidth  - 700) / 2;
        let modalTop = centerY - (window.innerHeight - 800) / 2 ;

        listLocales.style.left = `${modalLeft - 240 }px`
        listLocales.style.top = `${modalTop - 35}px`;
      }

      this.hoverRelease = release;
      this.showLocalesFullList = true;
    },
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>