<template>
  <the-new-header>
    <template v-slot:main-block>
      <div class="middle-part">
        <h1 class="page-title">Metadata Journal</h1>
        <span>My app:</span>
        <header-main-options :current-app="currentApp"/>
      </div>
    </template>
  </the-new-header>
  <div class="page-content metadata-journal">
    <slot name="sidebar"></slot>
    <main>
      <div class="metadata-journal-wrapper">
        <div class="header-container">
          <template v-if="currentMode === mods.view">
            <template v-if="showAppStoreApiConnect">
              <div class="connect-store-container">
                <template v-if="appStoreApiConnected">
                  <span @click="appStoreApiFetchReleases" class="connect-link update-releases" :class="{disabled : appStoreApiUpdateLimitError}">Update releases</span>
                  <p v-if="appStoreApiUpdateLimitError" class="limit-error" style="margin-right: 10px">Once per 10 minutes max.</p>
                  <p v-if="lastAppStoreApiUpdate.value && !appStoreApiUpdating">last update {{ this.lastAppStoreApiUpdate.text }}</p>
                  <p v-else-if="appStoreApiUpdating">updating...</p>
                </template>
                <template v-else>
                  <svg-icon icon="danger-icon"/>
                  <span> API key is not connected</span>
                  <a :href="appStoreApiConnectRoute.href" class="connect-link">Connect API Key App Store</a>
                </template>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="new-release-labels-container">
              <div class="title">{{ selectedRelease.is_edit ? 'Edit release' : 'New release'}}</div>
              <div class="status">Status:</div>
              <custom-chips v-if="isReleaseStatusSet"
                            :style="{'--chip-background-color': releaseStatus.color}"
                            @click="isReleaseStatusSet = !isReleaseStatusSet; searchInput = '';">
                {{ releaseStatus.label }}
              </custom-chips>
              <div class="search-status-container" v-else-if="!isReleaseStatusSet">
                <basic-search-input
                    placeholder="Search or create one"
                    :pre-set-value="searchInputPreSetValue"
                    :clear-input="searchInput"
                    :hide-results-block="true"
                    @search-input-changed="searchInputChanged"
                    @keydown.enter="editingStatus ? changeStatusLabel(searchInput) : addNewStatus({label: searchInput, color: newReleaseStatusDefaultColor})"
                >
                  <template v-slot:search-icon>
                    <svg-icon class="close-icon"
                              @click="clearSearchInput"
                              icon="close"/>
                  </template>
                </basic-search-input>
                <div class="results-block-wrap" @mouseleave="showEditStatus = false">
                  <div class="search-result-text">
                    {{ editingStatus ? 'Edit tag' : 'Select an option or create one'}}
                  </div>
                  <div class="results-block" v-for="status in releaseStatuses" :key="status.label">
                    <div class="result-item" v-if="!editingStatus">
                      <div class="product-name"
                           :style="{backgroundColor: status.color}"
                           @click="changeReleaseStatus(status)"
                      >
                        {{ status.label }}
                      </div>

                      <div class="three-dots-container"
                           v-if="!defaultReleaseStatuses.find(defaultStatus => defaultStatus.label === status.label)"
                           @mouseenter="changeNewStatus(status)"
                           :class="{active: showEditStatus}"
                      >
                        <span class="dots">...</span>
                      </div>
                      <div class="change-status"
                           :class="{ active : showEditStatus && editableStatusLabel === status.label}"
                           @mouseleave="onChangeStatusMouseleave()"
                           v-if="!defaultReleaseStatuses.find(defaultStatus => defaultStatus.label === status.label)">
                        Use color:
                        <div class="colors">
                          <div
                              class="color"
                              v-for="color in possibleColors"
                              :key="color"
                              :style="{backgroundColor: color}"
                              :class="{selected: selectedStatusColor === color}"
                              @click="editStatusColor(color, status)"
                          >
                          </div>
                        </div>
                        <div class="actions">
                          <div class="action-edit" @click="editStatusLabel(status)">
                            <svg-icon icon="edit-icon"/>
                            Edit
                          </div>
                          <div class="action-delete" @click="deleteStatus(status)">
                            <svg-icon icon="delete-icon-trash"/>
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="create-status" v-if="searchInput">
                    <div class="create-item" @click="editingStatus ? changeStatusLabel(searchInput) : addNewStatus({label: searchInput, color: newReleaseStatusDefaultColor})">
                      {{ editingStatus ? 'Save' : 'Create'}}
                      <div class="product-name"
                           :style="{backgroundColor: editingStatus ? editingStatus.color : newReleaseStatusDefaultColor}"
                      >
                        {{ searchInput }}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div class="new-release-action-buttons-container">
              <base-button height="36px"
                           width="140"
                           class="btn-standard save-edit-btn btn-save"
                           border-radius="4px"
                           font-size="16px"
                           :disabled="isEmptyObject(metadataByLocales)"
                           @click="onSaveButtonClick"
              >
                Save
              </base-button>
              <base-button height="36px"
                           class="btn-standard save-edit-btn btn-cancel"
                           font-size="16px"
                           @click="onCancelButtonClick"
              >
                Cancel
              </base-button>
            </div>
          </template>
        </div>
        <template v-if="currentMode === mods.view">
          <template v-if="releases.length > 0">
            <div class="filters-container">
              <div class="filters-block">
                <div class="collapse-button" @click="collapseClickHandler">
                  <template v-if="isAllUncollapsed">
                    <div>Collapse all</div>
                    <svg-icon icon="collapse"/>
                  </template>
                  <template v-else>
                    <div>Uncollapse all</div>
                    <svg-icon icon="uncollapse"/>
                  </template>
                </div>
                <div class="filter-item-wrap">
                  <custom-dropdown :close-dropdown="closePreparedByFilterDropdown">
                    <template v-slot:header>
                      <div class="filter-header">
                        <span>Prepared by</span>
                        <div class="item"
                             :style="{backgroundColor: '#DFE0FF'}"
                        >
                          {{ selectedPreparedBy.length === releasePreparedBy.length ? 'All' : selectedPreparedBy.length > 1 ? selectedPreparedBy.length : selectedPreparedBy[0]?.label }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="filter-block-wrap">
                        <div class="filter-pre-block">
                          <div class="filter-text">Selected : <span>{{ selectedPreparedBy.length }}</span></div>
                          <div class="filter-checked-all" @click="onSelectAllPreparedBy">
                            {{ selectedPreparedBy.length === releasePreparedBy.length ? '' :'Select All'}}
                          </div>
                        </div>
                        <div class="filter-block" v-for="status in releasePreparedBy" :key="status.label">
                          <div class="option filter-item">
                            <input type="checkbox"
                                   :id="status.label"
                                   :name="status.label"
                                   class="common-checkbox"
                                   :checked="selectedPreparedBy.includes(status)"
                                   :disabled="selectedPreparedBy.includes(status) && selectedPreparedBy.length === 1"
                                   @change="onPreparedByStatusClicked"
                            />
                            <label
                                class="product-name"
                                :for="status.label"
                                :style="{backgroundColor: status.color}">
                              {{ status.label }}
                            </label>
                          </div>
                        </div>
                        <div class="apply-btn">
                          <base-button height="36px"
                                       width="87px"
                                       class="btn-standard save-edit-btn"
                                       border-radius="4px"
                                       font-size="14px"
                                       @click="onPreparedByFilterApply">
                            Apply
                          </base-button>
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>

                </div>
                <div class="filter-item-wrap">
                  <custom-dropdown :close-dropdown="closeStatusFilterDropdown">
                    <template v-slot:header>
                      <div class="filter-header">
                        <span>Status</span>
                        <div class="item"
                             :style="{backgroundColor: '#DFE0FF'}"
                        >
                          {{ selectedStatuses.length === statuses.length ? 'All' : selectedStatuses.length > 1 ? selectedStatuses.length : selectedStatuses[0]?.label }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:content>
                      <div class="filter-block-wrap">
                        <div class="filter-pre-block">
                          <div class="filter-text">Selected : <span>{{ selectedStatuses.length }}</span></div>
                          <div class="filter-checked-all" @click="onSelectAllStatuses">
                            {{ selectedStatuses.length === statuses.length ? '' :'Select All'}}
                          </div>
                        </div>
                        <div class="filter-block" v-for="status in statuses" :key="status.label">
                          <div class="option filter-item">
                            <input type="checkbox"
                                   :id="status.label"
                                   :name="status.label"
                                   class="common-checkbox"
                                   :disabled="selectedStatuses.includes(status) && selectedStatuses.length === 1"
                                   :checked="selectedStatuses.includes(status)"
                                   @change="onStatusClicked"
                            />
                            <label
                                class="product-name"
                                :for="status.label"
                                :style="{backgroundColor: status.color}">
                              {{ status.label }}
                            </label>
                          </div>
                        </div>
                        <div class="apply-btn">
                          <base-button height="36px"
                                       width="87px"
                                       class="btn-standard save-edit-btn"
                                       border-radius="4px"
                                       font-size="14px"
                                       @click="onStatusFilterApply">
                            Apply
                          </base-button>
                        </div>
                      </div>
                    </template>
                  </custom-dropdown>
                </div>
                <div class="filter-item-wrap date-filter">
                  <custom-dropdown :close-dropdown="closeDateFilterDropdown">
                    <template v-slot:header>
                      <div class="filter-header">
                        <span> {{ currentDateFilter }}</span>
                      </div>
                    </template>
                    <template v-slot:content>
                        <div class="filter-item">
                          <div class="filter-text"
                               @click="onDateFilterChange">
                            {{ currentDateFilter === dateFilterDesc ? dateFilterAsc : dateFilterDesc }}
                          </div>
                        </div>
                    </template>
                  </custom-dropdown>
                </div>


              </div>
              <div class="create-release-container">
                <a class="download-button"
                   :href="fetchDownloadData()"
                   download
                >
                  <svg-icon icon="export-csv-icon"/>
                </a>
                <base-button height="36px"
                             width="171px"
                             class="btn-standard save-edit-btn"
                             border-radius="4px"
                             font-size="16px"
                             @click="showCreateReleaseModal = !showCreateReleaseModal">
                  <svg-icon icon="plus" />
                  Create Release
                </base-button>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div class="new-release-container">
            <div class="release-data">
              <template v-if="isEmptyObject(selectedRelease) && newReleaseName">
                <span>Release:</span>
                <div class="name">{{ newReleaseName }}</div>
              </template>
              <span>Date:</span>
              <div class="date">{{ selectedRelease.created_at ? formatDate(selectedRelease.created_at) : newReleaseDate }}</div>
            </div>
            <div v-if="isAppFromAppStore" class="load-data-from-meta-editor">
              <svg-icon icon="align-arrow-down"/>
              <a @click="showLoadReleaseFromMetaEditorModal = !showLoadReleaseFromMetaEditorModal">Load from Meta Editor</a>
            </div>
          </div>
        </template>

        <div class="releases-container">
          <template v-if="currentMode === mods.view">
            <template v-if="loading">
              <div>
                <progress-indicator>Loading data...</progress-indicator>
              </div>
            </template>
            <template v-else>
              <template v-if="releases.length > 0">
                <div class="releases-table-container" @mouseleave="showActionsReleaseMouseleave">
                  <simple-info-table
                      table-id="releases-table"
                      :full-width-section="true"
                      :items="releases"
                      :columns="columns"
                      default-sort-index="created"
                      :default-sort-direction="'desc'"
                      :sticky-header="true"
                      :table-wrap-styles="{maxHeight: 'calc(100vh - 316px)', overflowY: 'auto'}"
                      :table-wrap-classes="['common-scrollbar', 'scrollWidth-10', 'color-600', 'border-radius-8', 'background-color-400']"
                      :rows-per-page-list="[50, 100]"
                  >
                    <template v-slot:headers="slotProps">
                      <div style="width: 100%; text-align: left"
                           @mouseenter="showActionsReleaseMouseleave">
                        {{ slotProps.header }}
                      </div>
                    </template>

                    <template v-slot:items-created="slotProps">
                      {{ formatDate(slotProps.item.created_at) }}
                    </template>
                    <template v-slot:items-prepared-by="slotProps">
                      <div
                          :style="{backgroundColor: slotProps.item.prepared_by.color}"
                          style="padding: 4px 12px; border-radius: 4px;"
                      >
                        {{ slotProps.item.prepared_by.label }}
                      </div>
                    </template>
                    <template v-slot:items-status="slotProps">
                      <div
                          :style="{backgroundColor: slotProps.item.status.color}"
                          style="padding: 4px 12px; border-radius: 4px;"
                      >
                        {{ slotProps.item.status.label}}
                      </div>
                    </template>
                    <template v-slot:items-information="slotProps">
                      <div class="locales-list-container" @mouseenter="showActionsReleaseMouseleave">
                        <div class="locales">
                          Localizations ({{ Object.keys(slotProps.item.locales).length }})
                        </div>
                        <div class="locales-table-list" :style="{overflow: isShowingLocalesLimitExceeded(slotProps.item.locales) ? 'hidden' : 'visible'}">
                          <div
                              v-for="(data, locale) in slotProps.item.locales"
                              @mouseleave="showReleaseLocalesFullList = false"
                              @mouseenter="onReleaseListLocalesHover(slotProps.item)"
                          >
                            <dynamic-image
                                :key="locale"
                                :id="`locales-img${slotProps.item.id}`"
                                classes="country-flag"
                                :width="18"
                                :height="14"
                                :size="32"
                                :country-code="getLocaleCountryCode(locale)?.countryCode"/>
                          </div>
                        </div>
                        <div
                            class="three-dots"
                            ref="locales"
                            :id="`locales-${slotProps.item.id}`"
                            :class="{ show : isShowingLocalesLimitExceeded(slotProps.item.locales)}"
                            @mouseenter="onReleaseListLocalesHover(slotProps.item)"
                        >
                          ...
                        </div>
                      </div>
                      <div class="release-actions" @mouseenter="showActionsReleaseMouseleave">
                        <div class="show-details" @click="onDetailsClick(slotProps.index)">
                          <template v-if="!slotProps.item.show_details">
                            Show Details
                            <svg-icon icon="angle-down-solid"/>
                          </template>
                          <template v-else>
                            Hide Details
                            <svg-icon icon="angle-up-solid"/>
                          </template>
                        </div>
                        <div class="edit-or-delete" :id="`${slotProps.item.id}`"
                             @mouseenter="showActionsReleaseOnHover(slotProps.item)">
                          <svg-icon icon="three-dots-vertical"/>
                        </div>
                      </div>
                    </template>
                    <template v-slot:full-width-sections="slotProps">
                      <template v-if="slotProps.item.show_details">
                        <div class="comment-container">
                          <div class="comment-block">
                            <div class="comment-title">
                              Comment:
                            </div>
                            <div class="comment-content">
                              {{ slotProps.item.comment ?? ''}}
                            </div>
                          </div>

                          <div class="release-actions">
                            <div class="chart-btn"
                                 :class="{disabled: !isRankComparativeReportEnabled(slotProps.item)}"
                                 @click="redirectToRankComparativeReport(slotProps.item)"
                                 v-tooltip="{
                                    classes: ['release-actions-tooltip', 'no-wrap-text'],
                                    text: isRankComparativeReportEnabled(slotProps.item)
                                            ? 'Analyze position changes'
                                            : 'Release ranking analysis available only for releases made last 6 months.',
                                    position: 'bottom'
                                 }"
                            >
                              <svg-icon icon="chart-icon" class="icon-actions"/>
                            </div>
                            <div v-if="isAppFromAppStore"
                                 class="load-btn"
                                 @click="redirectToMetaEditor(slotProps.item)"
                                 v-tooltip="{ classes: ['release-actions-tooltip', 'no-wrap-text'], text: 'Load to Meta Editor', position: 'bottom'}">
                              <svg-icon icon="align-arrow-up" class="icon-actions"/>
                            </div>
                          </div>
                        </div>
                        <template v-for="(dataByLocale, locale) in slotProps.item.locales" :key="locale">
                          <table class="details-table">
                            <colgroup>
                              <col style="width: 24%;">
                              <col style="width: 38%;">
                              <col style="width: 38%;">
                            </colgroup>
                            <tr>
                              <td>
                                <div class="locales-content">
                                  <dynamic-image classes="country-flag"
                                                 :width="18"
                                                 :height="14"
                                                 :size="32"
                                                 :country-code="getLocaleCountryCode(locale)?.countryCode"/>
                                  {{ getLocaleCountryCode(locale)?.localeName  + " (" + locale + ")" }}
                                </div>
                              </td>
                              <td>
                                <div class="td-content">
                                  <div class="title-label">
                                    Title:
                                  </div>
                                  <div class="title-content">
                                    {{ dataByLocale.title }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="td-content">
                                  <div class="title-label">
                                    Subtitle:
                                  </div>
                                  <div class="title-content">
                                    {{ dataByLocale.subtitle }}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr class="tr_keywords">
                              <template v-if="isAppFromAppStore">
                                <td></td>
                                <td colspan="3">
                                  <div class="keywords-content">
                                    <div class="title-keywords">
                                      Keywords:
                                    </div>
                                    <div>
                                      {{ prepareKeywords(dataByLocale.keywords) }}
                                    </div>
                                  </div>
                                </td>
                              </template>
                              <template v-else>
                                <td colspan="4">
                                  <div class="description-table-block-wrap">
                                    <div class="description-table-block">
                                      <div class="description-block">
                                        <editable-table-cell
                                            classes="words-to-use-editable"
                                            :cell-type="'keyword-cell'"
                                            :show-length-limits="true"
                                            :text-max-length="4000"
                                            :initial-data="dataByLocale.description"
                                            :table-mode="'view'"
                                            :view-mode-placeholder="''"
                                        >
                                          <template v-slot:cell-title>Description</template>
                                        </editable-table-cell>
                                      </div>
                                      <div class="table-block">
                                        <MetadataKeywordsTable
                                            :store="currentApp.store.key"
                                            :max-count-plus="true"
                                            :table-container-styles="{boxShadow: 'none', border: '1px solid var(--neutral-400, #E1E8F2)', ...{display: 'grid', gridTemplateRows: 'max-content auto max-content', minHeight: '548px'}}"
                                            :title="dataByLocale.title"
                                            :sub-title="dataByLocale.subtitle"
                                            :description="dataByLocale.description"
                                            :init-count="3"
                                            :init-texts="['description']"
                                            :allowed-counts="[1, 2, 3]"
                                        >
                                        </MetadataKeywordsTable>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </table>
                        </template>
                      </template>
                    </template>
                  </simple-info-table>

                  <div class="show-locale-container"
                       :class="{active : showReleaseLocalesFullList }"
                       @mouseleave="showReleaseLocalesFullList = false"
                       @mouseenter="showReleaseLocalesFullList = true"
                  >
                    <ul class="locales-list-hover">
                      <li v-for="(data, locale) in hoverLocalesRelease.locales">
                        <dynamic-image classes="country-flag"
                                       :width="18"
                                       :height="14"
                                       :size="32"
                                       :country-code="getLocaleCountryCode(locale)?.countryCode"/>
                        {{ getLocaleCountryCode(locale)?.localeName + " (" + locale + ")"}}
                      </li>
                    </ul>
                  </div>

                  <div class="actions-on-hover"
                       :class="{ active :  editDeleteBtnShow.value  }"
                       @mouseleave="showActionsReleaseMouseleave"
                       :style="{top : `${actionsPosition.top}px`, left : `${actionsPosition.left}px`}"
                  >
                    <div class="action-edit" @click="editRelease(editDeleteBtnShow.release)">
                      <svg-icon icon="edit-icon"/>
                      Edit
                    </div>
                    <div class="action-delete" @click="deleteRelease(editDeleteBtnShow.release)">
                      <svg-icon icon="delete-icon-trash"/>
                      Delete
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="empty-releases-container">
                  <div class="empty-releases-title">
                    <h1>Welcome to Metadata Journal!</h1>
                    <h2>
                      Store and track all your metadata changes in one place
                    </h2>
                    <p>With Metadata Journal, you can log every update you make to your app's metadata, keeping a clear record of all changes.</p>
                    <p>This allows you to easily review and analyze your decisions over time, ensuring you stay on top of your optimization efforts.</p>
                  </div>
                  <div class="empty-releases-description">
                    <h3>Why it’s helpful:</h3>
                    <ul>
                      <li>Track your progress 🚀: See how your metadata evolves and which adjustments bring the best results.</li>
                      <li>Quickly return to previous versions⏮️: No more guessing about what worked before—access your past changes with just a click. Load it to Metadata Editor and start editing right away! ✏️</li>
                      <li>Easier analysis 🔍: Understand the impact of every change you made and improve your app's visibility in the future. Quick compare ranking before and after release.</li>
                    </ul>
                  </div>
                </div>
                <div class="create-release-container">
                  <base-button height="36px"
                               width="171px"
                               class="btn-standard save-edit-btn"
                               border-radius="4px"
                               font-size="16px"
                               @click="showCreateReleaseModal = !showCreateReleaseModal">
                    <svg-icon icon="plus" />
                    Create Release
                  </base-button>
                </div>
              </template>
            </template>
          </template>
          <template v-else>
            <div v-if="!isEmptyObject(this.metadataByLocales)" class="locales-table-container">
              <table>
                <colgroup>
                  <col style="width: 24%;">
                  <col style="width: 38%;">
                  <col style="width: 38%;">
                </colgroup>
                <thead>
                <tr>
                  <th v-for="column in localesTableColumns">
                    <div class="th_content">
                      {{ column }}
                      <template v-if="column === 'Localization'">
                        <div class="add-localization" @click="onAddLocalizationClick">
                          <svg-icon icon="plus"/>
                          Add
                        </div>
                      </template>
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(dataByLocale, locale) in metadataByLocales" :key="locale">
                  <tr>
                    <td>
                      <dynamic-image classes="country-flag"
                                     :width="18"
                                     :height="14"
                                     :size="32"
                                     :country-code="getLocaleCountryCode(locale)?.countryCode"/>
                      {{ getLocaleCountryCode(locale)?.localeName  + " (" + locale + ")" }}
                    </td>
                    <td>
                      <editable-table-cell
                          classes="words-to-use-editable"
                          :cell-type="'keyword-cell'"
                          :show-length-limits="true"
                          :text-max-length="30"
                          :initial-data="dataByLocale.title"
                          :table-mode="'edit'"
                          @editable-data-changed="e => editableDataChanged(e, 'title', locale)"
                      >
                      </editable-table-cell>
                    </td>
                    <td>
                      <editable-table-cell
                          classes="words-to-use-editable"
                          :cell-type="'keyword-cell'"
                          :show-length-limits="true"
                          :text-max-length="30"
                          :initial-data="dataByLocale.subtitle"
                          :table-mode="'edit'"
                          @editable-data-changed="e => editableDataChanged(e, 'subtitle', locale)"
                      >
                      </editable-table-cell>
                      <svg-icon class="close-icon"
                                @click="deleteLocaleMeta(locale)"
                                :style="{bottom : isAppFromAppStore ? '-6px' : '33px'}"
                                icon="close"/>
                    </td>

                  </tr>
                  <tr class="tr_keywords">
                    <template v-if="isAppFromAppStore">
                      <td></td>
                      <td colspan="3">
                        <div class="keywords">
                          <div class="title-keywords">
                            Keywords:
                          </div>
                          <editable-table-cell
                              classes="words-to-use-editable"
                              :cell-type="'keyword-cell'"
                              :show-length-limits="true"
                              :text-max-length="100"
                              :initial-data="prepareKeywords(dataByLocale.keywords)"
                              :table-mode="'edit'"
                              edit-mode-placeholder="Fill Keywords"
                              @editable-data-changed="e => editableDataChanged(e, 'keywords', locale)"
                          >
                          </editable-table-cell>
                        </div>
                      </td>
                    </template>
                    <template v-else class="">
                      <td colspan="4">
                        <div class="description-table-block-wrap">
                          <div class="description-table-block">
                            <div class="description-block">
                              <editable-table-cell
                                  classes="words-to-use-editable"
                                  :cell-type="'keyword-cell'"
                                  :show-length-limits="true"
                                  :text-max-length="4000"
                                  :initial-data="dataByLocale.description"
                                  :table-mode="'edit'"
                                  :edit-mode-placeholder="'Enter description'"
                                  @editable-data-changed="e => editableDataChanged(e, 'description', locale)"
                              >
                                <template v-slot:cell-title>Description</template>
                              </editable-table-cell>
                            </div>
                            <div class="table-block">
                              <MetadataKeywordsTable
                                  :store="currentApp.store.key"
                                  :max-count-plus="true"
                                  :table-container-styles="{boxShadow: 'none', border: '1px solid var(--neutral-400, #E1E8F2)', ...{display: 'grid', gridTemplateRows: 'max-content auto max-content', minHeight: '548px'}}"
                                  :title="dataByLocale.title"
                                  :sub-title="dataByLocale.subtitle"
                                  :description="dataByLocale.description"
                                  :init-count="3"
                                  :init-texts="['description']"
                                  :allowed-counts="[1, 2, 3]"
                              >
                              </MetadataKeywordsTable>
                            </div>
                          </div>
                        </div>

                      </td>
                    </template>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
            <div v-else class="empty-locales">
              <img src="@/assets/images/metadata-journal-empty-locales.svg" alt="Empty locales">
              No data yet. Add localizations to save metadata changes
              <base-button height="36px"
                           width="171px"
                           class="btn-standard save-edit-btn"
                           border-radius="4px"
                           font-size="16px"
                           @click="onAddLocalizationsClick"
              >
                <svg-icon icon="plus"/>
                Add localization
              </base-button>
            </div>
          </template>
        </div>
      </div>
    </main>
  </div>

  <create-release-modal
      :show-modal="showCreateReleaseModal"
      :current-app="currentApp"
      @modal-closed="createReleaseModalClose"
      @modal-submit="createReleaseModalSubmit"
  />
  <load-release-from-meta-editor-modal
      :show-modal="showLoadReleaseFromMetaEditorModal"
      :current-app="currentApp"
      @modal-closed="loadReleaseFromMetaEditorModalClose"
      @modal-submit="loadReleaseFromMetaEditorModalSubmit"
  />
  <add-localization-modal
      :show-modal="showAddLocalizationModal"
      :current-app="currentApp"
      :show-changed-locales="!!newReleaseName"
      :changed-locales="newReleaseChangedLocales"
      :already-selected-locales="alreadySelectedLocales"
      :release-date="newReleaseDate"
      @modal-closed="addLocalizationModalClose"
  />
  <save-release-modal
      :show-modal="showSaveReleaseModal"
      :current-app="currentApp"
      :release="selectedRelease"
      :statuses="releaseStatuses"
      :prepared-by="preparedByList"
      :colors="possibleColors"
      :default-release-statuses="defaultReleaseStatuses"
      :default-release-prepared-by="defaultReleasePreparedBy"
      @modal-closed="saveReleaseModalClose"
      @modal-submit="saveReleaseModalSubmit"
  />
  <delete-release-modal
      :show-modal="showDeleteReleaseModal"
      :release="selectedRelease"
      @modal-closed="deleteReleaseModalClose"
      @modal-submit="deleteReleaseModalSubmit"
  />
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import CreateReleaseModal from "@/views/MetadataJournal/CreateReleaseModal/index.vue";
import {mapGetters} from "vuex";
import CustomChips from "@/components/Forms/CustomChips/index.vue";
import LoadReleaseFromMetaEditorModal from "@/views/MetadataJournal/LoadReleaseFromMetaEditorModal/index.vue";
import AddLocalizationModal from "@/views/MetadataJournal/AddLocalizationModal/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import EditableTableCell from "@/views/MetaEditor/components/EditableTableCell/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import HeaderMainOptions from "@/components/UI/HeaderMainOptions/index.vue";
import {formatDate} from "@/utils/utils";
import SaveReleaseModal from "@/views/MetadataJournal/SaveReleaseModal/index.vue";
import {httpRequest} from "@/api";
import SimpleInfoTable from "@/components/SimpleInfoTable/SimpleInfoTable.vue";
import DeleteReleaseModal from "@/views/MetadataJournal/DeleteReleaseModal/index.vue";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import MetadataKeywordsTable from "@/views/CompetitorsFinderPage/Competitors/Metadata/MetadataKeywordsTable.vue";
import {apiUrls} from "@/api/api";
import metadataJournal from "@/api/modules/metadata-journal";

export default {
  name: 'MetadataJournal',
  components: {
    MetadataKeywordsTable,
    ProgressIndicator,
    DeleteReleaseModal,
    SimpleInfoTable,
    CreateReleaseModal,
    SaveReleaseModal,
    AddLocalizationModal,
    LoadReleaseFromMetaEditorModal,
    HeaderMainOptions,
    EditableTableCell,
    BasicSearchInput,
    CustomChips,
    CustomDropdown,
    SvgIcon,
  },
  data() {
    return {
      loading: false,
      showAppStoreApiConnect: false,
      appStoreApiConnected: false,
      lastAppStoreApiUpdate: {},
      appStoreApiUpdateLimitError: false,
      appStoreApiUpdating: false,

      mods: {
        view: 'view',
        create: 'create',
      },
      currentMode: null,
      
      showCreateReleaseModal: false,
      showLoadReleaseFromMetaEditorModal: false,
      showAddLocalizationModal: false,
      showSaveReleaseModal:false,
      showDeleteReleaseModal: false,
      
      newReleaseName: null,
      newReleaseDate: null,
      
      searchInput: '',
      searchInputPreSetValue: '',
      metadataByLocales: {},
      newReleaseChangedLocales: {},
      alreadySelectedLocales: [],
      visibleLocalesCount: 7,
      showLocalesFullList: false,
      
      possibleColors: [
        '#CAD5E5',
        '#C9F6C5',
        '#CDFDEF',
        '#D6F3FF',
        '#D7E0FF',
        '#E5DCFF',
        '#FFBEED',
        '#FED4D4',
        '#FFD9B5',
        '#FFF0BA',
        '#E9DCC7',
        '#E2CECE',
      ],
      
      defaultReleaseStatuses: [
        {
          label: 'Released',
          color: '#DEFDDC'
        },
        {
          label: 'In review',
          color: '#FFF0BA'
        },
        {
          label: 'Rejected',
          color: '#EC5C3E29'
        },
      ],
      statuses: [],//user stored statuses on back
      manuallyAddedStatuses: [], //user created on front, but doesn't store on back
      releaseStatuses: [],//defaultReleaseStatuses + statuses + manuallyAddedStatuses

      newReleaseStatusDefaultColor: '#E1E8F2',
      editingStatus: null,
      showEditStatus: false,
      editableStatusLabel: '',
      selectedStatusColor: '',
      releaseStatus: {},
      isReleaseStatusSet: true,
      selectedStatuses: [],
      
      releases: [],
      initialReleases: [],
      selectedRelease: {},
      hoverRelease: {},
      hoverLocalesRelease: {},
      
      dateFilterDesc: 'Newest first',
      dateFilterAsc: 'Oldest first',
      currentDateFilter: null,
      showReleaseLocalesFullList: false,
      showPreparedByFiltersBlock: false,
      closePreparedByFilterDropdown: 0,
      closeStatusFilterDropdown: 0,
      closeDateFilterDropdown: 0,

      actionsPosition: {},
      
      isAllUncollapsed: false,
      editDeleteBtnShow: {
        value: false,
        release: {}
      },

      releasePreparedBy: [],
      preparedByList: [],
      selectedPreparedBy: [],
      defaultReleasePreparedBy: [
        {
          label: 'Me',
          color: '#D6F3FF',
        },
        {
          label: 'Other Agency',
          color: '#DEFDDC',
        },
        {
          label: 'Owner',
          color: '#E5DCFF',
        },
      ],
      showLocalesCount: 7,
    }
  },
  created() {
    this.currentMode = this.mods.view;
    this.currentDateFilter = this.dateFilterDesc;
  },
  async mounted () {
    if (this.isAppFromAppStore) {
      this.showAppStoreApiConnect = true;
      this.appStoreApiConnected = await this.fetchIsAppStoreApiConnected();
    }

    await this.fetchReleases();
    this.releaseStatus = this.defaultReleaseStatuses[0];
  },
  computed: {
    ...mapGetters([
        'currentApp',
    ]),
    isAppFromAppStore() {
      return this.currentApp.store.key === 'APP_STORE';
    },
    localesTableColumns() {
      return this.isAppFromAppStore
          ? ['Localization', 'Title', 'Subtitle']
          : ['Localization', 'Title', 'Short Description'];
    },
    columns() {
      return  {
        'created': {
          header: 'Created',
          orientation: 'left',
          style: {width: '200px'}
        },
        'prepared-by': {
          header: 'Prepared By',
          orientation: 'left',
          style: {width: '240px', paddingRight: 0, whiteSpace: 'nowrap', textAlign: 'center'},
          headerStyle: {textAlign: 'center'},
        },
        'status': {
          header: 'Status',
          style: {width: '240px'},
          headerStyle: {minWidth: '130px', paddingRight: '0px'},
          orientation: 'left',
        },
        'information': {
          header: 'Information',
          style: {width: '480px'},
          headerStyle: {minWidth: '125px'},
          orientation: 'left',
        },
      };
    },
    appStoreApiConnectRoute() {
      return this.$router.resolve({
        name: 'IntegrationSettingsASAPI',
        params: { app_id: this.currentApp.id }
      });
    }
  },
  watch: {
    async currentApp(newApp, oldApp) {
      if (newApp.id !== oldApp.id) {
        this.showAppStoreApiConnect = false;
        this.lastAppStoreApiUpdate = {};
        this.appStoreApiUpdateLimitError = false;

        if (newApp.store.key === 'APP_STORE') {
          this.showAppStoreApiConnect = true;
          this.appStoreApiConnected = await this.fetchIsAppStoreApiConnected();
        }

        this.releases = [];
        this.currentMode = this.mods.view;
        this.metadataByLocales = {};
        await this.fetchReleases();
      }
    },
  },
  methods: {
    formatDate,
    getLocaleCountryCode,
    async fetchIsAppStoreApiConnected() {
      const response = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.IS_APP_STORE_API_CONNECTED + '?app_id=' + this.currentApp.id,
      );

      const connected = response?.result;

      if (connected) {
        this.lastAppStoreApiUpdate.value = response?.updated_at;
        this.lastAppStoreApiUpdate.text = this.lastAppStoreApiUpdateWas(response.updated_at)
      }

      return connected;
    },
    async fetchReleases() {
      this.loading = true;
      const response = await metadataJournal.getReleases(this.currentApp.id);

      if (response.length > 0) {
        this.initialReleases = response;
        this.releases = this.initialReleases;
        this.getPreparedBy();
        this.preparedByList = [
          ...new Map([
            ...this.releasePreparedBy,
            ...this.defaultReleasePreparedBy
          ].map(item => [item.label, item])).values()
        ];
        this.getStatuses();
        this.releaseStatuses = [
          ...new Map([
            ...this.statuses,
            ...this.defaultReleaseStatuses
          ].map(item => [item.label, item])).values()
        ];
        this.applyFilters();
      } else {
        this.releases = [];
        this.releaseStatuses = [...this.defaultReleaseStatuses];
        this.preparedByList = [...this.defaultReleasePreparedBy];
      }

      this.loading = false;
    },
    createReleaseModalClose() {
      this.showCreateReleaseModal = !this.showCreateReleaseModal;
    },
    createReleaseModalSubmit(releaseName, releaseDate, selectedRelease) {
      this.currentMode = this.mods.create;
      this.newReleaseName = releaseName
      this.newReleaseDate = releaseDate;
      this.newReleaseChangedLocales = selectedRelease?.changed_locales;
      this.createReleaseModalClose();
    },
    loadReleaseFromMetaEditorModalSubmit(metadataByLocales) {
      this.showLoadReleaseFromMetaEditorModal = !this.showLoadReleaseFromMetaEditorModal;
      this.metadataByLocales = {...metadataByLocales};
    },
    loadReleaseFromMetaEditorModalClose() {
      this.showLoadReleaseFromMetaEditorModal = !this.showLoadReleaseFromMetaEditorModal;
    },
    addLocalizationModalClose(metadataByLocales) {
      this.showAddLocalizationModal = !this.showAddLocalizationModal;

      if (metadataByLocales) {
        this.metadataByLocales = {...metadataByLocales};
      }
    },
    deleteReleaseModalClose() {
      this.showDeleteReleaseModal = !this.showDeleteReleaseModal;
      this.selectedRelease = {};
    },
    deleteReleaseModalSubmit() {
      this.showDeleteReleaseModal = !this.showDeleteReleaseModal;
      this.selectedRelease = {};
      this.fetchReleases();
    },
    onAddLocalizationsClick() {
      this.showAddLocalizationModal = !this.showAddLocalizationModal;
      this.alreadySelectedLocales = [];
    },
    onAddLocalizationClick() {
      this.alreadySelectedLocales = this.metadataByLocales ? Object.keys(this.metadataByLocales) : [];
      this.showAddLocalizationModal = true;
    },
    onCancelButtonClick() {
      this.currentMode = this.mods.view;
      this.isReleaseStatusSet = true;
      this.selectedRelease = {};
      this.releaseStatus = this.defaultReleaseStatuses[0];
      this.metadataByLocales = {};
      this.manuallyAddedStatuses = [];
      this.statuses = this.getDataByProperty('status');
    },
    onSaveButtonClick() {
      if (!this.selectedRelease.status) { //create release
        this.selectedRelease = {
          locales: this.metadataByLocales,
          status: this.releaseStatus,
          prepared_by: this.defaultReleasePreparedBy[0],
          comment: '',
          created_at: this.newReleaseDate,
        }
      } else { //edit release
        this.selectedRelease.locales = this.metadataByLocales;
        this.selectedRelease.status = this.releaseStatus;
      }
      this.showSaveReleaseModal = !this.showCreateReleaseModal;
    },
    saveReleaseModalClose() {
      this.showSaveReleaseModal = !this.showSaveReleaseModal;
    },
    saveReleaseModalSubmit() {
      this.showSaveReleaseModal = !this.showSaveReleaseModal;
      this.currentMode = this.mods.view;
      this.metadataByLocales = {};
      this.selectedRelease = {};
      this.isReleaseStatusSet = true;
      this.releaseStatus = this.defaultReleaseStatuses[0];
      this.manuallyAddedStatuses = [];
      this.fetchReleases();
    },
    clearSearchInput() {
      this.isReleaseStatusSet = !this.isReleaseStatusSet;
      this.searchInput = '';
      this.releaseStatuses = this.getAllStatuses();
    },
    searchInputChanged(search) {
      if (this.editingStatus && search === '') {
        this.searchInput = this.editingStatus.label;
        this.searchInputPreSetValue = this.editingStatus.label;

        return;
      }

      this.releaseStatuses = this.getAllStatuses();
      this.releaseStatuses = this.releaseStatuses.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      this.searchInput = search;
    },
    getAllStatuses() {
      return [ ...new Map([
        ...this.manuallyAddedStatuses,
        ...this.statuses,
        ...this.defaultReleaseStatuses,
      ].map(item => [item.label, item])).values()];
    },
    isEmptyObject(object) {
      return JSON.stringify(object) === '{}';
    },
    changeStatusLabel(newLabel) {
      if (this.searchInput !== '') {
        const status = {
          label: newLabel,
          color: this.editingStatus.color,
        };

        let index = this.manuallyAddedStatuses.findIndex(item => item.label === this.editingStatus.label);
        if (index !== -1) {
          this.manuallyAddedStatuses[index] = status;
        } else {
          index = this.statuses.findIndex(item => item.label === this.editingStatus.label);

          if (index !== -1) {
            this.statuses[index] = status;
          }
        }

        this.releaseStatuses = this.getAllStatuses();
        this.searchInput = '';
        this.searchInputPreSetValue = '';
        this.editingStatus = null;
        this.changeReleaseStatus(status);
      }

    },
    addNewStatus(status) {
      if (this.searchInput !== '') {
        this.releaseStatuses = this.getAllStatuses();
        this.releaseStatuses.push(status);
        this.manuallyAddedStatuses.push(status);

        this.searchInput = '';
        this.changeReleaseStatus(status);
      }
    },
    changeNewStatus(status) {
      this.editableStatusLabel = status.label;
      this.showEditStatus = true;
    },
    onChangeStatusMouseleave() {
      this.showEditStatus = false;
      this.selectedStatusColor = '';
    },
    editStatusColor(newStatusColor, status) {
      if (newStatusColor) {
        const selectedStatus = this.releaseStatuses.find(item => item.label === status.label);

        if (selectedStatus) {
          selectedStatus.color = newStatusColor;
        }
      }

      this.showEditStatus = false;
    },
    editStatusLabel(status) {
      this.releaseStatuses = [];
      this.searchInput = status.label;
      this.searchInputPreSetValue = status.label;
      this.editingStatus = {
        label: status.label,
        color: status.color,
      };
      this.showEditStatus = false
    },
    deleteStatus(status) {
      this.releaseStatus = this.defaultReleaseStatuses[0];
      this.manuallyAddedStatuses = this.manuallyAddedStatuses.filter(releaseStatus => releaseStatus.label !== status.label);
      this.statuses = this.statuses.filter(releaseStatus => releaseStatus.label !== status.label);
      this.releaseStatuses = this.getAllStatuses();
    },
    changeReleaseStatus(status) {
      this.releaseStatus = status;
      this.isReleaseStatusSet = true;
      this.editingStatus = null;
      this.searchInputPreSetValue = '';
      this.releaseStatuses = this.getAllStatuses();
    },
    deleteLocaleMeta(locale) {
      delete this.metadataByLocales[locale];
    },
    onReleaseListLocalesHover(release) {
      if (!this.showReleaseLocalesFullList) {
        const sidebarWidth = document.querySelector('aside.sidebar')?.clientWidth || 0;
        const element = !this.isShowingLocalesLimitExceeded(release.locales)
            ? document.querySelector(`#locales-img${release.id}`)
            : document.querySelector(`#locales-${release.id}`);
        const listLocales = document.querySelector(`.show-locale-container`);
        const rect = element.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const horizontalMargin = (window.innerWidth > 2500) ? (window.innerWidth - 1920) / 2 : 0;
        listLocales.style.left = !this.isShowingLocalesLimitExceeded(release.locales)
            ? `${centerX - 25 - sidebarWidth - horizontalMargin}px`
            : `${centerX - 200 - sidebarWidth - horizontalMargin}px`
        listLocales.style.top = `${centerY + 15}px`;
      }

      this.hoverLocalesRelease = release;
      this.showReleaseLocalesFullList = true;
    },
    onPreparedByFilterApply() {
      this.closePreparedByFilterDropdown++;
      this.applyFilters();
    },
    onStatusFilterApply() {
      this.closeStatusFilterDropdown++;
      this.applyFilters();
    },
    onDateFilterChange() {
      this.closeDateFilterDropdown++;
      this.currentDateFilter = this.currentDateFilter === this.dateFilterDesc
          ? this.dateFilterAsc
          : this.dateFilterDesc;

      this.applyFilters();
    },
    applyFilters() {
      let filteredReleases = this.initialReleases;

      if (this.selectedStatuses.length > 0) {
        filteredReleases = filteredReleases.filter(
            item => this.selectedStatuses.some(selected => selected.label === item.status.label)
        );
      }

      if (this.selectedPreparedBy.length > 0) {
        filteredReleases = filteredReleases.filter(
            item => this.selectedPreparedBy.some(selected => selected.label === item.prepared_by.label)
        );
      }

      filteredReleases = this.currentDateFilter === this.dateFilterDesc
          ? filteredReleases.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          : filteredReleases.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))

      this.releases = filteredReleases;
    },
    collapseClickHandler() {
      this.isAllUncollapsed = !this.isAllUncollapsed;
      this.releases.forEach(item => item.show_details = this.isAllUncollapsed);
    },
    onDetailsClick(index) {
      this.releases[index].show_details = !this.releases[index].show_details;
      this.showActionsReleaseMouseleave();
    },
    onPreparedByStatusClicked(event) {
      const clickedStatus = this.releasePreparedBy.find(status => status.label === event.target.id);

      if (clickedStatus) {
        const isChecked = event.target.checked;

        if (isChecked) {
          this.selectedPreparedBy.push(clickedStatus);
        } else {
          this.selectedPreparedBy = this.selectedPreparedBy.filter(status => status.label !== clickedStatus.label);
        }
      }
    },
    onStatusClicked(event) {
      const clickedStatus = this.statuses.find(status => status.label === event.target.id);

      if (clickedStatus) {
        const isChecked = event.target.checked;

        if (isChecked) {
          this.selectedStatuses.push(clickedStatus);
        } else {
          this.selectedStatuses = this.selectedStatuses.filter(status => status.label !== clickedStatus.label);
        }
      }
    },
    onSelectAllPreparedBy() {
      this.selectedPreparedBy = [...this.releasePreparedBy];
    },
    onSelectAllStatuses() {
      this.selectedStatuses = [...this.statuses];
    },
    getDataByProperty(propertyName) {
      const data = [...this.releases];

      return Object.values(
          data.reduce((acc, item) => {
            const property = item[propertyName];

            if (property && property.label) {
              const key = `${property.label}`;

              if (!acc[key]) {
                acc[key] = {
                  label: property.label,
                  color: property.color,
                };
              }
            }

            return acc;
          }, {})
      );
    },
    getPreparedBy() {
      this.releasePreparedBy = this.getDataByProperty('prepared_by');
      this.selectedPreparedBy = [...this.releasePreparedBy];
    },
    getStatuses() {
      this.statuses = this.getDataByProperty('status');
      this.selectedStatuses = [...this.statuses];
    },
    editRelease(release) {
      this.selectedRelease = {...release};
      this.selectedRelease.is_edit = true;
      this.metadataByLocales = JSON.parse(JSON.stringify(release.locales));
      this.releaseStatuses = [
        ...new Map([
          ...this.statuses,
          ...this.defaultReleaseStatuses
        ].map(item => [item.label, item])).values()
      ];
      this.releaseStatus = release.status;
      this.currentMode = this.mods.create;
      this.showActionsReleaseMouseleave();
    },
    deleteRelease(release) {
      this.selectedRelease = release;
      this.showDeleteReleaseModal = !this.showDeleteReleaseModal;
    },
    showActionsReleaseOnHover(release) {
      if (this.editDeleteBtnShow.value && this.editDeleteBtnShow.release.id !== release.id) {
        this.editDeleteBtnShow.value = false;
      }

      if (!this.editDeleteBtnShow.value) {
        const sidebarWidth = document.querySelector('aside.sidebar')?.clientWidth || 0;
        const element = document.getElementById(release.id);
        this.editDeleteBtnShow.value = true;
        this.editDeleteBtnShow.release = release;

        const rect = element.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        const horizontalMargin = (window.innerWidth > 2500) ? (window.innerWidth - 1920) / 2 : 0;

        this.actionsPosition = {
          left: centerX - 70 - sidebarWidth - horizontalMargin ,
          top: centerY  + 50,
        };
      }
    },
    showActionsReleaseMouseleave() {
      this.showReleaseLocalesFullList = false;
      this.editDeleteBtnShow.value = false;
      this.editDeleteBtnShow.release = {};
    },
    editableDataChanged(newValue, metadataField, locale) {
      this.metadataByLocales[locale][metadataField] = newValue;
    },
    fetchDownloadData() {
      const releaseIds = this.releases.map(item => item.id);
      const orderBy = this.currentDateFilter === this.dateFilterDesc ? 'desc' : 'asc';
      const urlParameters = `?app_id=${this.currentApp.id}&release_ids=${encodeURIComponent(releaseIds.join(','))}&order_by=${orderBy}`;

      return process.env.VUE_APP_URL + this.$ApiUrls.exportsUrls.METADATA_JOURNAL + urlParameters;
    },
    async redirectToRankComparativeReport(release) {
      if (this.isRankComparativeReportEnabled(release)) {
        let parameters = `?locale_codes=${encodeURIComponent(Object.keys(release.locales).join(','))}&store_key=${this.currentApp.store.key}`;
        this.loading = true;
        const countries = await httpRequest(
            'GET',
            process.env.VUE_APP_API_URL + apiUrls.metadataJournal.GET_COUNTRIES_BY_LOCALES + parameters,
        );
        const countryCodes = Object.values(countries);
        this.loading = false;

        const [date] = release.created_at.split(' ');
        const [year, month, day] = date.split('-');
        const formattedDate = `${day}-${month}-${year}`;
        const route = this.$router.resolve({name: 'RankComparativeReport'});
        parameters = `?app_id=${this.currentApp.id}&date_from=${formattedDate}&date_to=${formattedDate}&source=ranking&release=null`;
        const countyCodesQueryString = countryCodes.map(code => `country_codes[]=${code}`).join('&');

        window.open(route.fullPath + parameters + countyCodesQueryString, '_blank');
      }
    },
    isShowingLocalesLimitExceeded(locales) {
      return Object.keys(locales).length > this.showLocalesCount;
    },
    prepareKeywords(keywords) {
      return keywords ?? '';
    },
    isRankComparativeReportEnabled(release) {
      const releaseDate = new Date(release.created_at);
      const sixMonthsAgo = new Date().setMonth(new Date().getMonth() - 6);

      return releaseDate >= sixMonthsAgo;
    },
    redirectToMetaEditor(release) {
      const route = this.$router.resolve({name: 'MetaEditor'});
      window.open(route.fullPath + '?release_id=' + release.id, '_blank');
    },
    lastAppStoreApiUpdateWas(date) {
      const pastTime = new Date(date + 'Z');
      const currentUtcTime = Date.now();
      const pastUtcTime = pastTime.getTime();

      const diffMs = currentUtcTime - pastUtcTime;
      let diffMinutes = Math.floor(diffMs / (1000 * 60));

      if (diffMinutes < 60) {
        if (diffMinutes === 0) {
          diffMinutes = 1;
        }

        return `${diffMinutes} minute${diffMinutes === 1 ? '' : 's'} ago`;
      } else {
        const diffHours = Math.floor(diffMinutes / 60);
        return `${diffHours} hour${diffHours === 1 ? '' : 's'} ago`;
      }
    },
    hasPassedTenMinutes() {
      const pastTime = new Date(this.lastAppStoreApiUpdate.value + 'Z');
      const currentTime = new Date();
      const diffMs = currentTime - pastTime;
      const diffMinutes = Math.floor(diffMs / (1000 * 60));
      return diffMinutes >= 10;
    },
    async appStoreApiFetchReleases() {
      if (this.lastAppStoreApiUpdate.value && !this.hasPassedTenMinutes()) {
        this.appStoreApiUpdateLimitError = true;
        return;
      }

      this.appStoreApiUpdating = true;
      const response = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.APP_STORE_UPDATE_RELEASES + '?app_id=' + this.currentApp.id,
      );
      this.appStoreApiUpdating = false;

      if (response?.result) {
        if (response?.updated_at) {
          this.lastAppStoreApiUpdate.value = response.updated_at;
          this.lastAppStoreApiUpdate.text = this.lastAppStoreApiUpdateWas(response.updated_at)
        }
        await this.fetchReleases();
      }
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>