<template>
  <div class="create-release">
    <custom-modal
        :opened="showModal"
        :max-width="650"
        class-name="save-release-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper">
        <div class="title">Save Release</div>
        <div class="release-data-container">
          <div class="release-prepared-by">
            <div class="title">Prepared by:</div>
            <custom-chips v-if="isReleasePreparedBySet"
                          :style="{'--chip-background-color': initRelease.prepared_by ? initRelease.prepared_by.color : release.prepared_by.color}"
                          @click="isReleasePreparedBySet = !isReleasePreparedBySet; searchPreparedByInput = '';">
              {{ initRelease.prepared_by ? initRelease.prepared_by.label : release.prepared_by.label  }}
            </custom-chips>
            <div class="search-status-container" v-else>
              <basic-search-input
                  placeholder="Search or create one"
                  :pre-set-value="searchPrepareByInputPreSetValue"
                  :clear-input="searchPreparedByInput"
                  :hide-results-block="true"
                  @search-input-changed="searchPreparedByInputChanged"
                  @keydown.enter="editingPreparedBy ? changePreparedByLabel(searchPreparedByInput) : addNewPreparedBy({label: searchPreparedByInput, color: newPreparedByDefaultColor})"
              >
                <template v-slot:search-icon>
                  <svg-icon class="close-icon "
                            @click="clearSearchPreparedByInput"
                            icon="close"/>
                </template>
              </basic-search-input>
              <div class="results-block-wrap common-scrollbar" @mouseleave="showEditPreparedBy = false">
                <div class="search-result-text">
                  {{ editingPreparedBy ? 'Edit tag' : 'Select an option or create one'}}
                </div>
                <div class="results-block" v-for="item in releasePreparedByList" :key="item.label">
                  <div class="result-item" v-if="!editingPreparedBy">
                    <div class="product-name"
                         :style="{backgroundColor: item.color}"
                         @click="changeReleasePreparedBy(item)" >
                      {{ item.label }}
                    </div>

                    <div class="three-dots-container"
                         v-if="!defaultReleasePreparedBy.find(defaultPreparedBy => defaultPreparedBy.label === item.label)"
                         @mouseenter="changeNewPreparedBy(item)"
                         :class="{active: editingPreparedBy}"
                    >
                      <span class="dots">...</span>
                    </div>
                    <div class="change-status"
                         :class="{ active : showEditPreparedBy && editablePreparedByLabel === item.label}"
                         @mouseleave="onChangePreparedByMouseleave()"
                         v-if="!defaultReleasePreparedBy.find(defaultPreparedBy => defaultPreparedBy.label === item.label)">
                      Use color:
                      <div class="colors">
                        <div
                            class="color"
                            v-for="color in colors"
                            :key="color"
                            :style="{backgroundColor: color}"
                            @click="editPreparedBy(color, item)"
                        >
                        </div>
                      </div>
                      <div class="actions">
                        <div class="action-edit" @click="editPreparedByLabel(item)">
                          <svg-icon icon="edit-icon"/>
                          Edit
                        </div>
                        <div class="action-delete" @click="deletePreparedBy(item)">
                          <svg-icon icon="delete-icon-trash"/>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="create-status" v-if="searchPreparedByInput">
                  <div class="create-item" @click="editingPreparedBy ? changePreparedByLabel(searchPreparedByInput) : addNewPreparedBy({label: searchPreparedByInput, color: newPreparedByDefaultColor})">
                    {{ editingPreparedBy ? 'Save' : 'Create'}}
                    <div class="product-name"
                         :style="{backgroundColor: editingPreparedBy ? editingPreparedBy.color : newPreparedByDefaultColor}"
                         >
                      {{ searchPreparedByInput }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="release-status">
            <div class="title">Status:</div>
            <custom-chips v-if="isReleaseStatusSet"
                          :style="{'--chip-background-color': release.status.color}"
                          @click="isReleaseStatusSet = !isReleaseStatusSet; searchStatusInput = '';">
              {{ release.status.label }}
            </custom-chips>
            <div class="search-status-container"  v-else>
              <basic-search-input
                  placeholder="Search or create one"
                  :pre-set-value="searchStatusInputPreSetValue"
                  :clear-input="searchStatusInput"
                  :hide-results-block="true"
                  @search-input-changed="searchStatusInputChanged"
                  @keydown.enter="editingStatus ? changeStatusLabel(searchStatusInput) : addNewStatus({label: searchStatusInput, color: newStatusDefaultColor})"
              >
                <template v-slot:search-icon>
                  <svg-icon class="close-icon "
                            @click="clearSearchStatusInput"
                            icon="close"/>
                </template>
              </basic-search-input>
              <div class="results-block-wrap common-scrollbar" @mouseleave="showEditStatus = false">
                <div class="search-result-text">
                  {{ editingStatus ? 'Edit tag' : 'Select an option or create one'}}
                </div>
                <div class="results-block" v-for="status in releaseStatuses" :key="status.label">
                  <div class="result-item" v-if="!editingStatus">
                    <div class="product-name"
                         :style="{backgroundColor: status.color}"
                         @click="changeReleaseStatus(status)" >
                      {{ status.label }}
                    </div>

                    <div class="three-dots-container"
                         v-if="!defaultReleaseStatuses.find(defaultStatus => defaultStatus.label === status.label)"
                         @mouseenter="changeNewStatus(status)"
                         :class="{active: showEditStatus}"
                    >
                      <span class="dots">...</span>
                    </div>
                    <div class="change-status"
                         :class="{ active : showEditStatus && editableStatusLabel === status.label}"
                         @mouseleave="onChangeStatusMouseleave()"
                         v-if="!defaultReleaseStatuses.find(defaultStatus => defaultStatus.label === status.label)">
                      Use color:
                      <div class="colors">
                        <div
                            class="color"
                            v-for="color in colors"
                            :key="color"
                            :style="{backgroundColor: color}"
                            @click="editStatus(color, status)"
                        >
                        </div>
                      </div>
                      <div class="actions">
                        <div class="action-edit" @click="editStatusLabel(status)">
                          <svg-icon icon="edit-icon"/>
                          Edit
                        </div>
                        <div class="action-delete" @click="deleteStatus(status)">
                          <svg-icon icon="delete-icon-trash"/>
                          Delete
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="create-status" v-if="searchStatusInput">
                  <div class="create-item" @click="editingStatus ? changeStatusLabel(searchStatusInput) : addNewStatus({label: searchStatusInput, color: newStatusDefaultColor})" >
                    {{ editingStatus ? 'Save' : 'Create'}}
                    <div class="product-name"
                         :style="{backgroundColor: editingStatus ? editingStatus.color : newStatusDefaultColor}"
                         >
                      {{ searchStatusInput }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="release-comment-container">
          <editable-table-cell
              classes="words-to-use-editable"
              :cell-type="'keyword-cell'"
              :show-length-limits="true"
              :text-max-length="250"
              :initial-data="release.comment"
              :table-mode="'edit'"
              edit-mode-placeholder="Leave your comment"
              @editable-data-changed="releaseCommentChanged"
          >
            <template v-slot:cell-title>Comment</template>
          </editable-table-cell>
        </div>
        <div class="action-buttons">
          <template v-if="showPreloader">
            <preloader-table-small :loader-size="'40px'"></preloader-table-small>
          </template>
          <template v-else>
            <base-button height="36px"
                         width="171px"
                         class="btn-standard save-edit-btn btn-back"
                         border-radius="4px"
                         font-size="16px"
                         @click="close"
            >
              Back to Editing
            </base-button>
            <base-button height="36px"
                         width="171px"
                         class="btn-standard save-edit-btn btn-save"
                         border-radius="4px"
                         font-size="16px"
                         @click="submit"
            >
              Save
            </base-button>
          </template>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>

import CustomChips from "@/components/Forms/CustomChips/index.vue";
import TextAreaBlock from "@/components/Forms/TextAreaBlock/index.vue";
import BasicSearchInput from "@/components/UI/BasicSearchInput/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import EditableTableCell from "@/views/MetaEditor/components/EditableTableCell/index.vue";
import {httpRequest} from "@/api";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";

export default {
  name: 'SaveReleaseModal',
  components: {PreloaderTableSmall, EditableTableCell, SvgIcon, BasicSearchInput, TextAreaBlock, CustomChips},
  emits: ['modal-closed', 'modal-submit'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentApp: {
      type: Object,
    },
    release: {
      type: Object,
    },
    statuses: {
      type: Array,
    },
    preparedBy: {
      type: Array,
    },
    colors: {
      type: Array,
    },
    defaultReleaseStatuses: {
      type: Array,
    },
    defaultReleasePreparedBy: {
      type: Array,
    }
  },
  data () {
    return {
      showPreloader: false,

      newStatusDefaultColor: '#E1E8F2',
      newPreparedByDefaultColor: '#CDFDEF',
      manuallyAddedStatuses: [],
      manuallyAddedPreparedBy: [],

      searchStatusInput: '',
      showEditStatus: false,
      editingStatus: null,
      searchStatusInputPreSetValue: '',
      searchPreparedByInput: '',
      showEditPreparedBy: false,
      editingPreparedBy: null,
      searchPrepareByInputPreSetValue: '',
      isReleaseStatusSet: true,
      editableStatusLabel: '',
      editablePreparedByLabel: '',
      releaseStatuses: [],
      releasePreparedByList: [],

      releaseStatusesList: [],
      preparedByList: [],
      initRelease : {},
      isReleasePreparedBySet: true,
    }
  },
  mounted () {
    this.initValues();
  },
  updated () {
    this.initValues();
  },
  methods: {
    initValues(){
      this.releaseStatusesList = JSON.parse(JSON.stringify(this.statuses));
      this.preparedByList = JSON.parse(JSON.stringify(this.preparedBy));
      this.initRelease = JSON.parse(JSON.stringify(this.release));

      this.releaseStatuses = this.releaseStatusesList ? [...this.releaseStatusesList] : [...this.defaultReleaseStatuses];
      this.releasePreparedByList = this.preparedByList ? [...this.preparedByList] : [...this.defaultReleasePreparedBy];
    },
    close() {
      this.releaseStatusesList = [...this.statuses];
      this.preparedByList = [...this.preparedBy];
      this.initRelease =  {...this.release};
      this.manuallyAddedStatuses = [];
      this.manuallyAddedPreparedBy = [];
      this.isReleasePreparedBySet = true;
      this.isReleaseStatusSet = true;
      this.$emit('modal-closed');
    },
    async submit() {
      this.showPreloader = true;
      let data = {
        app_id: this.currentApp.id,
        metas: {
          locales: this.release.locales,
          status: this.release.status,
          prepared_by: this.initRelease.prepared_by,
          comment: this.release.comment,
        },
        created_at: this.formatReleaseDate(this.release.created_at),
      };

      if (this.release.id) { //update
        data.release_id = this.release.id;
      }

      await httpRequest(
          'POST',
          process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.SAVE_RELEASE,
          data,
      );
      this.showPreloader = false;
      this.$emit('modal-submit');
    },
    searchStatusInputChanged(search) {
      this.releaseStatuses = this.getAllStatuses();

      if (this.editingStatus && search === '') {
        this.searchStatusInput = this.editingStatus.label;
        this.searchStatusInputPreSetValue = this.editingStatus.label;

        return;
      }
      
      this.releaseStatuses = this.releaseStatuses.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      this.searchStatusInput = search;
    },
    clearSearchStatusInput() {
      this.isReleaseStatusSet = !this.isReleaseStatusSet;
      this.searchStatusInput = '';
      this.releaseStatuses = this.getAllStatuses();
    },
    changeReleaseStatus(status) {
      this.release.status = status;
      this.isReleaseStatusSet = true;
      this.editingStatus = null;
      this.releaseStatuses = this.getAllStatuses();
    },
    changeNewStatus(status) {
      this.editableStatusLabel = status.label;
      this.showEditStatus = true;
    },
    onChangeStatusMouseleave() {
      this.showEditStatus = false;
    },
    editStatus(newStatusColor, status) {
      if (newStatusColor) {
        const selectedStatus = this.releaseStatuses.find(item => item.label === status.label);

        if (selectedStatus) {
          selectedStatus.color = newStatusColor;
        }
      }

      this.showEditStatus = false;
    },
    editStatusLabel(status) {
      this.releaseStatuses = [];
      this.searchStatusInput = status.label;
      this.searchStatusInputPreSetValue = status.label;
      this.editingStatus = {
        label: status.label,
        color: status.color,
      };
    },
    deleteStatus(status) {
      this.release.status = this.defaultReleaseStatuses[0];
      this.manuallyAddedStatuses = this.manuallyAddedStatuses.filter(releaseStatus => releaseStatus.label !== status.label);
      this.releaseStatusesList = this.releaseStatusesList.filter(releaseStatus => releaseStatus.label !== status.label);
      this.releaseStatuses = this.releaseStatuses.filter(releaseStatus => releaseStatus.label !== status.label);
    },
    changeStatusLabel(newLabel) {
      if (this.searchStatusInput !== '') {
        const status = {
          label: newLabel,
          color: this.editingStatus.color,
        };

        let index = this.manuallyAddedStatuses.findIndex(item => item.label === this.editingStatus.label);

        if (index !== -1) {
          this.manuallyAddedStatuses[index] = status;
        } else {
          index = this.releaseStatusesList.findIndex(item => item.label === this.editingStatus.label);

          if (index !== -1) {
            this.releaseStatusesList[index] = status;
          }
        }

        this.releaseStatuses = this.getAllStatuses();
        this.searchStatusInput = '';
        this.searchStatusInputPreSetValue = '';
        this.editingStatus = null;
        this.changeReleaseStatus(status);
      }

    },
    addNewStatus(status) {
      if (this.searchStatusInput !== '') {
        this.releaseStatuses = [...this.defaultReleaseStatuses, ...this.manuallyAddedStatuses];
        this.releaseStatuses.push(status);
        this.manuallyAddedStatuses.push(status)
        this.searchStatusInput = '';
        this.changeReleaseStatus(status);
      }
    },
    searchPreparedByInputChanged(search) {
      this.releasePreparedByList = this.getAllPreparedBy();

      if (this.editingPreparedBy && search === '') {
        this.searchPreparedByInput = this.editingPreparedBy.label;
        this.searchPrepareByInputPreSetValue = this.editingPreparedBy.label;

        return;
      }

      this.releasePreparedByList = this.releasePreparedByList.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))
      this.searchPreparedByInput = search;
    },
    clearSearchPreparedByInput() {
      this.isReleasePreparedBySet = !this.isReleasePreparedBySet;
      this.searchPreparedByInput = '';
      this.releasePreparedByList = this.getAllPreparedBy();
    },
    changeReleasePreparedBy(preparedBy) {
      this.initRelease.prepared_by = preparedBy;
      this.isReleasePreparedBySet = true;
      this.editingPreparedBy = null;
      this.releasePreparedByList = this.getAllPreparedBy();
    },
    changeNewPreparedBy(status) {
      this.editablePreparedByLabel = status.label;
      this.showEditPreparedBy = true;
    },
    onChangePreparedByMouseleave() {
      this.showEditPreparedBy = false;
    },
    editPreparedBy(newPreparedByColor, preparedBy) {
      if (newPreparedByColor) {
        const selectedPreparedBy = this.releasePreparedByList.find(item => item.label === preparedBy.label);

        if (selectedPreparedBy) {
          selectedPreparedBy.color = newPreparedByColor;
        }
      }

      this.showEditPreparedBy= false;
    },
    editPreparedByLabel(preparedBy) {
      this.releasePreparedByList = [];
      this.searchPreparedByInput = preparedBy.label;
      this.searchPrepareByInputPreSetValue = preparedBy.label;
      this.editingPreparedBy = {
        label: preparedBy.label,
        color: preparedBy.color,
      };
    },
    deletePreparedBy(preparedBy) {
      this.initRelease.prepared_by = this.defaultReleasePreparedBy[0];
      this.manuallyAddedPreparedBy = this.manuallyAddedPreparedBy.filter(releasePreparedBy => releasePreparedBy.label !== preparedBy.label);
      this.preparedByList = this.preparedByList.filter(releasePreparedBy => releasePreparedBy.label !== preparedBy.label);
      this.releasePreparedByList = this.getAllPreparedBy();
    },
    changePreparedByLabel(newLabel) {
      if (this.searchPreparedByInput !== '') {
        const preparedBy = {
          label: newLabel,
          color: this.editingPreparedBy.color,
        };

        let index = this.manuallyAddedPreparedBy.findIndex(item => item.label === this.editingPreparedBy.label);

        if (index !== -1) {
          this.manuallyAddedPreparedBy[index] = preparedBy;
        } else {
          index = this.preparedByList.findIndex(item => item.label === this.editingPreparedBy.label);

          if (index !== -1) {
            this.preparedByList[index] = preparedBy;
          }
        }

        this.releasePreparedByList = this.getAllPreparedBy();
        this.searchPreparedByInput = '';
        this.searchPrepareByInputPreSetValue = '';
        this.editingPreparedBy = null;
        this.changeReleasePreparedBy(preparedBy);
      }
    },
    getAllPreparedBy() {
      return [ ...new Map([
        ...this.manuallyAddedPreparedBy, //user created on front, but doesn't store on back
        ...this.preparedByList, //user stored on back
        ...this.defaultReleasePreparedBy,
      ].map(item => [item.label, item])).values()];
    },
    getAllStatuses() {
      return [ ...new Map([
        ...this.manuallyAddedStatuses, //user created on front, but doesn't store on back
        ...this.releaseStatusesList, //user stored on back
        ...this.defaultReleaseStatuses,
      ].map(item => [item.label, item])).values()];
    },
    addNewPreparedBy(preparedBy) {
      if (this.searchPreparedByInput !== '') {
        this.releasePreparedByList = [...this.defaultReleasePreparedBy, ...this.manuallyAddedPreparedBy];
        this.releasePreparedByList.push(preparedBy);
        this.manuallyAddedPreparedBy.push(preparedBy)
        this.searchPreparedByInput = '';
        this.changeReleasePreparedBy(preparedBy);
      }
    },
    formatReleaseDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return`${year}-${month}-${day}`;
    },
    releaseCommentChanged(value) {
      this.release.comment = value;
    }
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>