<template>
  <div class="create-release">
    <custom-modal
        :opened="showModal"
        :max-width="650"
        class-name="delete-release-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper">
        <div class="title">Do you want to delete this release from journal?</div>
        <div class="action-buttons">
          <template v-if="loading">
            <preloader-table-small :loader-size="'40px'"></preloader-table-small>
          </template>
          <template v-else>
            <base-button height="36px"
                         width="171px"
                         class="btn-standard save-edit-btn btn-back"
                         border-radius="4px"
                         font-size="16px"
                         @click="close"
            >
              Cancel
            </base-button>
            <base-button height="36px"
                         width="171px"
                         class="btn-standard save-edit-btn btn-save"
                         border-radius="4px"
                         font-size="16px"
                         @click="submit"
            >
              Delete
            </base-button>
          </template>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>

import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import {httpRequest} from "@/api";

export default {
  name: 'DeleteReleaseModal',
  components: {PreloaderTableSmall},
  emits: ['modal-closed', 'modal-submit'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    release: {
      type: Object,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    close() {
      this.$emit('modal-closed');
    },
    async submit() {
      this.loading = true;
      const param = '?release_id=' + this.release.id;
      const endpoint = process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.DELETE_RELEASE + param;
      await httpRequest('DELETE', endpoint);
      this.loading = false;
      this.$emit('modal-submit');
    },
  }
}
</script>
<style lang="scss" scoped src="./styles.scss"></style>