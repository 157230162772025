<template>
  <div class="create-release">
    <custom-modal
        :opened="showModal"
        :max-width="650"
        class-name="create-release-modal"
        @closeModal="close"
    >
      <div class="modal-wrapper">
        <div class="title">Choose release</div>
        <div class="description">Choose release or date</div>
        <div class="actions">
          <div class="container">
            <div class="select-from-releases">
              <span>Release:</span>
              <div class="filter-item-wrap">
                <custom-dropdown :close-dropdown="closeDropdown"  @mouseleave="hideLocalesList">
                  <template v-slot:header>
                    <div class="filter-header">
                      <span>{{ releasePickerValue }}</span>
                    </div>
                  </template>
                  <template v-slot:content>
                    <div class="select-options">
                      <div class="locales-list-container">
                        <ul class="locales-list">
                          <li v-for="locale in filteredLocales">
                            <dynamic-image classes="country-flag"
                                           :width="18"
                                           :height="14"
                                           :size="32"
                                           :country-code="getLocaleCountryCode(locale)?.countryCode"/>
                            {{ getLocaleCountryCode(locale)?.localeName }}
                          </li>
                        </ul>
                      </div>
                      <div class="option"
                           v-for="release in filteredReleases"
                           :key="release.version"
                           @mouseenter="onHoverReleaseDropdown(release)"
                           @click="onSelectRelease(release)"
                           >

                        {{ getReleaseLabel(release) }}
                        <span v-if="release.changed_locales_count > 0" class="changed-locales">{{ release.changed_locales_count }} locales changed</span>
                      </div>
                    </div>
                  </template>
                </custom-dropdown>
              </div>
            </div>
            <div class="select-from-date">
              <span>or Date</span>
              <div @click="showDatePicker = !showDatePicker" :class="showDatePicker ? 'bordered' : ''">
                <svg-icon icon="calendar-icon" />
                <label>{{ datePickerValue }}</label>
              </div>

            </div>
          </div>
          <div class="date-picker-container" :class="showDatePicker ? 'active' : ''">
            <div class="calendar-block-wrap">
              <date-picker
                  :min-date="minDate"
                  :max-date="maxDate"
                  @dayclick="onSelectDate($event)"
              />
            </div>
          </div>
        </div>
        <div class="next-button">
          <base-button height="36px"
                       width="171px"
                       class="btn-standard save-edit-btn"
                       border-radius="4px"
                       font-size="16px"
                       :disabled="isNextButtonDisabled"
                       @click="submit"
          >
            Next
            <svg-icon icon="arrow-right" />
          </base-button>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>

import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {httpRequest} from "@/api";
import {formatDate, getNDaysAgoDate} from "@/utils/utils";
import {DatePicker} from 'v-calendar';
import {getLocaleCountryCode} from "@/configs/locales-flags";

export default {
  name: 'CreateReleaseModal',
  components: {DatePicker, SvgIcon, CustomDropdown},
  emits: ['modal-closed', 'modal-submit'],
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    currentApp: {
      type: Object,
    },
  },
  data () {
    return {
      selectedRelease: {},
      releases: {},
      hoverRelease: {},

      showDatePicker: false,
      releasePickerDefaultValue: 'Select release',
      releasePickerValue: null,
      datePickerDefaultValue: 'Choose',
      datePickerValue: null,
      minDate: getNDaysAgoDate(365),
      maxDate: new Date(),

      closeDropdown:  0,
    }
  },
  created() {
    this.setDefaultReleaseValues();
  },
  async mounted() {
    await this.fetchReleases();
  },
  updated() {
    this.setDefaultReleaseValues();
  },
  computed: {
    filteredReleases() {
      return this.releases.map(release => {
        const filteredLocales = release.changed_locales.filter(
            locale => this.getLocaleCountryCode(locale)
        );
        const updatedChangedLocalesCount = filteredLocales.length;

        return {
          ...release,
          changed_locales: filteredLocales,
          changed_locales_count: updatedChangedLocalesCount,
        };
      });
    },
    filteredLocales() {
      return (this.hoverRelease?.changed_locales || []).filter(
          locale => this.getLocaleCountryCode(locale)
      );
    },
    isNextButtonDisabled() {
      return this.datePickerValue === this.datePickerDefaultValue;
    }
  },
  watch: {
    async currentApp(newApp, oldApp) {
      if (newApp.id !== oldApp.id) {
        await this.fetchReleases();
      }
    }
  },
  methods: {
    getLocaleCountryCode,
    formatDate,
    close() {
      this.showDatePicker = false;
      this.$emit('modal-closed');
    },
    submit() {
      this.$emit('modal-submit', this.releasePickerValue, this.datePickerValue, this.selectedRelease);
    },
    setDefaultReleaseValues() {
      this.releasePickerValue = this.releasePickerDefaultValue;
      this.datePickerValue = this.datePickerDefaultValue;
    },
    async fetchReleases() {
      const url = '?app_id=' + this.currentApp.id;
      this.releases = await httpRequest(
          'GET',
          process.env.VUE_APP_API_URL + this.$ApiUrls.metadataJournal.GET_ARAHIS_RELEASES + url,
      );
    },
    onSelectRelease(release) {
      this.showDatePicker = false;
      this.closeDropdown++;
      this.selectedRelease = release;
      this.releasePickerValue = this.getReleaseLabel(release);
      this.datePickerValue = formatDate(release.date);
      this.hideLocalesList();
    },
    getReleaseLabel(release) {
      return release.version + ' from ' +  formatDate(release.date);
    },
    onSelectDate(event) {
      const date = event.id;
      this.datePickerValue = formatDate(date)
      this.showDatePicker = false;
      this.selectedRelease = null;
      const releaseWithSameDate = this.releases.find(release => release.date === date);
      this.selectedRelease =  releaseWithSameDate ? releaseWithSameDate : null;

      this.releasePickerValue = releaseWithSameDate
          ? this.getReleaseLabel(releaseWithSameDate)
          : null;
    },
    onHoverReleaseDropdown(release) {
      if (release.changed_locales.length > 0) {
        this.hoverRelease = release;
        const localesUl = document.getElementsByClassName('locales-list')[0];
        localesUl.style.display = 'block';
      } else {
        this.hideLocalesList();
      }
    },
    hideLocalesList() {
      const localesUl = document.getElementsByClassName('locales-list')[0];
      localesUl.style.display = 'none';
    }
  }
}
</script>
<style lang="scss" src="./styles.scss"></style>